import React, { useState } from "react";
import { Link } from "gatsby";
// import data from "../data/en.json";
import ContactUsForm from "./Modal/ContactUsForm";

export default function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const WhyAtlasCard = data;
  // const whyus = data[0].Menu.whyus;

  return (
    <footer className="">
      <div className="footer-container">
        <div className="">
          <p className="footer-titletext">
            Find out how <span>Atlas</span> can take your store to the next
            level
          </p>
        </div>

        <button
          type="button"
          className="btnGetInTouch"
          data-bs-toggle="modal"
          data-bs-target="#getintouchmodal"
          onClick={handleShow}
        >
          Get in touch
        </button>
        <ContactUsForm show={show} onClose={handleClose} onShow={handleShow} />
      </div>

      <div id="footer">
        <div className="container-fluid">
          <div className="container c-wrapper  g-0">
            <div className="fsc-wrapper">
              <div className="f-layout1-container  g-0">
                <Link
                  className="link-secondary text-white ft-title"
                  to="/order-fulfillment"
                >
                  Order Fulfillment
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle"
                  to="/click-and-collect"
                >
                  Click & Collect
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle"
                  to="/ship-from-store"
                >
                  Ship from Store
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle"
                  to="/pre-order"
                >
                  Pre-orders
                </Link>
              </div>

              <div className="f-layout2-container  g-0">
                <Link
                  className="link-secondary text-white ft-title "
                  to="/inventory-management"
                >
                  Inventory Management
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle"
                  to="/backdoor-scanning"
                >
                  Backdoor Scanning
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle "
                  to="/shelf-edge-labelling"
                >
                  Shelf Edge Labelling
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle "
                  to="/cycle-count"
                >
                  Cycle Count
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle "
                  to="/stock-adjustments"
                >
                  Stock Adjustments
                </Link>

                <Link
                  className="link-secondary text-white ft-subtitle"
                  to="/store-transfers"
                >
                  Store Transfers
                </Link>
              </div>
              <div className="f-layout3-container">
                <Link
                  className="link-secondary text-white ft-title"
                  to="/about"
                >
                  Company
                </Link>

                <Link
                  className="link-secondary text-white ft-title"
                  to="#"
                  onClick={handleShow}
                >
                  Contact
                </Link>
              </div>
              <div className="f-layout4-container  f-row">
                <div className="f-layout4-container">
                  <Link
                    className="link-secondary text-white ft-subtitle"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>

                  <Link
                    className="link-secondary text-white ft-subtitle"
                    to="/terms-and-conditions"
                  >
                    Terms of service
                  </Link>

                  <p className="link-secondary text-muted  ft-subtitle" to="#">
                    © Atlas4Stores
                  </p>
                </div>

                <div className="ft-logo-container">
                  <img src="/images/logowhite.svg" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
