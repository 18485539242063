import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { StaticImage } from "gatsby-plugin-image";
//import { Script } from "gatsby"
import countrycode from "../../data/countrycode.json";

export default function ContactUsForm(props) {
  const { show, onClose } = props;

  const countrycodelist = countrycode;
  const countrycodelist1 = [...countrycodelist].sort(
    (a, b) => a.dial_code - b.dial_code
  );
  let countryCode = countrycodelist1.filter(
    (ele, ind) =>
      ind ===
      countrycodelist1.findIndex((elem) => elem.dial_code === ele.dial_code)
  );

  // For Validations
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    countryCodeValue: "",
    phone: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const [buttonDisable, setButtonDisable] = useState(true)

  const [smShow, setSmShow] = useState(false);

  useEffect(() => {
    // console.log(formErrors)
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(formValues)
      let mobileNumber = `${formValues.countryCodeValue}`.concat(
        `${formValues.phone}`
      );
      // console.log(formValues.countryCodeValue, mobileNumber, "fds");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: `${formValues.firstName}`,
          lastName: `${formValues.lastName}`,
          phone: mobileNumber,
          email: `${formValues.email}`,
          message: `${formValues.message}`,
        }),
      };

      fetch("https://atlas4stores.com/send-email", requestOptions)
        .then((response) => console.log(response))
        .catch((error) => console.log(error));

      onClose();
      setSmShow(true);
      setFormValues({
        firstName: "",
        lastName: "",
        email: "",
        countryCodeValue: "",
        phone: "",
        message: "",
      });
      //  console.log(formValues)
    }
  }, [formErrors]);

  const handleChange = (e) => {
    //  console.log(e.target);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log(formValues)
    if (Object.keys(formErrors).length === 0) {
      //setButtonDisable(false)
      setIsSubmit(true);
    }
  };

  const handleSubmit = (e) => {
    console.log("submit");
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formValues, "form");
    //   window.Email.send({
    //     Host : "smtp.elasticemail.com",
    //     Username : "bharani_a@zakapps.com",
    //
    //     To : 'bharani_a@zakapps.com',
    //     From : "bharani_a@zakapps.com",
    //     Subject : "This is the subject",
    //     Body : `<div>${formValues.firstName}
    //                 <h1>Bharani</h1>
    //                 <p>And this is the body</p></div>`
    // })

    //   const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       firstName: `${formValues.firstName}`,
    //       lastName: `${formValues.lastName}`,
    //       phone: `${formValues.phone}`,
    //       email: `${formValues.email}`,
    //       message: `${formValues.message}`,
    //     }),
    //   };

    //   fetch("https://atlas4stores.com/send-email", requestOptions)
    //     .then((response) => console.log(response))
    //     .catch((error) => console.log(error));
    // };
  };

  const validate = (values) => {
    const errors = {};
    const regex = new RegExp("^[a-zA-Z ]*$");
    const regex1 = new RegExp("^[a-z0-9_]+@[a-z]+.[a-z]{2,3}");
    const regex2 = new RegExp("^[0-9]");

    if (!values.firstName) {
      errors.firstName = "First Name is Required!";
    } else if (!regex.test(values.firstName)) {
      errors.firstName = "Only Alphabets are Allowed!";
    }

    if (!values.lastName) {
      errors.lastName = "Last Name is Required!";
    } else if (!regex.test(values.lastName)) {
      errors.lastName = "Only Alphabets are Allowed!";
    }
    if (!values.countryCodeValue) {
      errors.phone = "Country code is Required!";
    }

    if (!values.phone) {
      errors.phone = "Phone Number is Required!";
    } else if (!regex2.test(values.phone)) {
      errors.phone = "Only Numbers are allowed!";
    } else if (values.phone.length > 10) {
      errors.phone = "Enter 10 digit Mobile Number";
    } else if (values.phone.length < 10) {
      errors.phone = "Enter 10 digit Mobile Number";
    }

    if (!values.email) {
      errors.email = "Email is Required!";
    } else if (!regex1.test(values.email)) {
      errors.email = "Please Enter Valid Email Format!";
    }

    if (!values.message) {
      errors.message = "Message is Required!";
    }
    return errors;
  };

  return (
    <>
      {/* <Script src="https://smtpjs.com/v3/smtp.js" /> */}
      <div id="ContactFromWrap">
        <Modal show={show} onHide={onClose}>
          <div id="getintouchmodal">
            <div className="form-wrapper">
              <div className="fc-wrap">
                <div className="content-wrap">
                  {/*  / */}

                  <div className="mob-logo-wrap">
                    <img src="/../../images/logo.svg" alt="" />
                    <Modal.Header closeButton />
                  </div>

                  <h2>Get in touch</h2>
                  <div className="fd1">
                    <div>
                      <label htmlFor="input1" className="">
                        First name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        className=""
                        id="input1"
                        placeholder=""
                        required
                        value={formValues.firstName}
                        onChange={handleChange}
                      />
                      <span>{formErrors.firstName}</span>{" "}
                    </div>

                    <div>
                      <label htmlFor="input2" className="">
                        Last name
                      </label>
                      <input
                        type="text"
                        className=""
                        name="lastName"
                        id="input2"
                        placeholder=""
                        value={formValues.lastName}
                        onChange={handleChange}
                      />
                      <span>{formErrors.lastName}</span>{" "}
                    </div>
                  </div>
                  <div className="fd2">
                    <div>
                      <label htmlFor="input3" className="form-label ">
                        Phone number
                      </label>
                      <div>
                        <select
                          name="countryCodeValue"
                          id="input3"
                          className="input3Code"
                          onChange={handleChange}
                          value={formValues.countryCodeValue}
                        >
                          <option
                            name="countryCodeValue"
                            key="0"
                            value=""
                          ></option>
                          {countryCode &&
                            countryCode.map((item) => {
                              return (
                                <option
                                  name="countryCodeValue"
                                  key={item.code}
                                  value={item.dial_code}
                                >
                                  {item.dial_code}
                                </option>
                              );
                            })}
                        </select>
                        <input
                          type="text"
                          name="phone"
                          inputMode="numeric"
                          className="input3Number"
                          id="input3"
                          placeholder=""
                          value={formValues.phone}
                          onChange={handleChange}
                        />
                        <span>{formErrors.phone}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="input4" className="">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className=""
                        id="input4"
                        placeholder=""
                        value={formValues.email}
                        onChange={handleChange}
                      />{" "}
                      <span>{formErrors.email}</span>{" "}
                    </div>
                  </div>
                  <div className="fd3">
                    <label htmlFor="comment" className="">
                      Message
                    </label>
                    <textarea
                      className=""
                      id="comment"
                      name="message"
                      value={formValues.message}
                      onChange={handleChange}
                    ></textarea>
                    <span>{formErrors.message}</span>
                  </div>
                  <div className="fd4">
                    {" "}
                    <input
                      type="submit"
                      value="Submit"
                      className="submit-btn"
                      onClick={handleSubmit}
                      // disabled={buttonDisable}
                    />
                  </div>
                </div>
                <div className="img-wrap">
                  {" "}
                  <StaticImage
                    src="../../images/signupbg.webp"
                    alt=""
                    layout="fullWidth"
                  />
                  {/* <img
                    className="img-fluid"
                    src="/images/signupbg.webp"
                    alt=""
                  />{" "} */}
                </div>
              </div>
              <div className="form-footer d-flex">
                <div>
                  <img
                    className="img-fluid"
                    style={{ height: "23px" }}
                    src="/images/logowhite.svg"
                    alt="logo"
                  />
                </div>
                <div className="d-flex">
                  <div className="d-flex">
                    <img className="img-fluid" src="/images/email.svg" alt="" />

                    <h2 className="fw-normal pt-2 common-text-inter">
                      sales@atlas4stores.com
                    </h2>
                  </div>
                  <div className="d-flex">
                    <img className="img-fluid" src="/images/phone.svg" alt="" />

                    <h2 className="fw-normal pe-5 pt-2 common-text-inter">
                      01908 109881
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          id="successModal"
        >
          <Modal.Header>
            <Modal.Title id="successTitle">
              <h4>
                <b>Thank You</b>
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="successBody">
            <p>We will contact you shortly!</p>
            <button onClick={() => setSmShow(false)}>Close</button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
