import React, { useState } from "react";
import { Link } from "gatsby";

import ContactUsForm from "./Modal/ContactUsForm";
import MobMenu from "./Modal/MobMenu";
import data from "../data/Lang/English/homePage.json";

export default function Navbar() {
  //menu data
  // const WhyAtlasCard = data[0].Menu;
  const whyus = data[0].Menu.whyus;
  const { inventory, inventorytwo, ordermenu } = data[0].Menu;

  // contact form start
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // contact form end

  // mob menu start
  const [mobShow, setmobShow] = useState(false);
  const handleMobClose = () => setmobShow(false);
  const handleMobShow = () => setmobShow(true);
  // mob menu end

  return (
    <div className="antialiased sticky-top nav-bar-wrapper">
      <nav className="navbar  navbar-expand-md bg-light nav-parent">
        <div className=" container-md custom_nav_sm">
          <Link to="/">
            <img className="img-fluid" src="/images/logo.svg" alt="Atlas" />
          </Link>
          <div>
            <button
              type="button"
              className="contactbutton d-md-none ms-5 pt-2 justify-content-end"
              data-bs-toggle="modal"
              data-bs-target="#getintouchmodal"
              onClick={handleShow}
            >
              Contact
            </button>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleMobShow}
            >
              <img
                className="img-fluid p-1"
                src="/images/menuicon.png"
                alt="icon"
              />
            </button>
          </div>

          <div
            className="collapse navbar-collapse py-2  ms-xl-5 justify-content-sm-end  justify-content-xl-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-xl-5">
              <li className="nav-item pt-3 pt-md-2 dropdown li-menu">
                <div className="menu-dummy-area">&nbsp;</div>
                <Link
                  className="nav-link common-text-inter"
                  aria-current="page"
                  to="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="solution"
                >
                  Solutions
                </Link>

                <div className="menu-overlap li-submenu">
                  <div>
                    {/* This element is for  */}
                    <div className="menu-overlap-side-solution"></div>
                    <div
                      className="dropdown-menu solution-card-menu ps-set co"
                      id="home-menu-dd-wrapper"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <div className="close-menu">
                        <img
                          src="/images/close-menu-icon.svg"
                          alt=""
                          id="close-menu-icon"
                        />
                      </div>
                      <div className="table-wrapper">
                        <div className="lt-container">
                          <div className="header lt-header">
                            <Link
                              to={"/" + ordermenu.url}
                              className="text-decoration-none mx-0"
                            >
                              <p>{ordermenu.title}</p>
                            </Link>
                          </div>

                          {ordermenu.list.map((list) => (
                            <div className="lt-sub-header" key={list.id}>
                              <Link
                                to={"/" + list.url}
                                className="mx-0 text-decoration-none"
                              >
                                <h3 className="lt-title title">
                                  {list.title}{" "}
                                </h3>
                                <p className="lt-subtitle subtitle">
                                  {list.subtitle}
                                </p>
                              </Link>
                            </div>
                          ))}
                        </div>

                        <div className="ct-divider"></div>
                        <div className="rt-container">
                          <div className="header rt-header">
                            <Link
                              to={"/" + inventory.url}
                              className="mx-0 text-decoration-none"
                            >
                              <p>{inventory.title}</p>
                            </Link>
                          </div>

                          <div className="sub-wrapper">
                            <div className="sub-one">
                              {inventory.list.map((list) => (
                                <div
                                  className="rt-sub-header hm-lg-6"
                                  key={list.id}
                                >
                                  <Link
                                    to={"/" + list.url}
                                    className="mx-0 text-decoration-none"
                                  >
                                    <h3 className="rt-title title">
                                      {list.title}{" "}
                                    </h3>
                                    <p className="rt-subtitle subtitle">
                                      {list.subtitle}
                                    </p>
                                  </Link>
                                </div>
                              ))}
                            </div>

                            <div className="sub-two">
                              {inventorytwo.list.map((list) => (
                                <div
                                  className="rt-sub-header hm-lg-6"
                                  key={list.id}
                                >
                                  <Link
                                    to={"/" + list.url}
                                    className="mx-0 text-decoration-none "
                                  >
                                    <h3 className="rt-title title">
                                      {list.title}{" "}
                                    </h3>
                                    <p className="rt-subtitle subtitle">
                                      {list.subtitle}
                                    </p>
                                  </Link>
                                </div>
                              ))}
                            </div>

                            <div className="header rt-header menu-sm-block">
                              <Link
                                to="#"
                                className="mx-0 text-decoration-none d-none"
                              >
                                <p>Clients</p>
                              </Link>
                            </div>

                            <div className="header rt-header menu-sm-block">
                              <Link
                                to="/about"
                                className="mx-0 text-decoration-none"
                              >
                                <p>About us</p>
                              </Link>
                            </div>

                            <div className="header rt-header  border-0 menu-sm-block">
                              <Link
                                to="/why-atlas"
                                className="mx-0 text-decoration-none"
                                activeClassName="active_Link"
                              >
                                <p className=" border-0">Why Atlas</p>
                              </Link>
                            </div>

                            <div className="sub-three menu-sm-block">
                              {whyus.list.map((list) => (
                                <div
                                  className="rt-sub-header hm-lg-6"
                                  key={list.id}
                                >
                                  <Link
                                    to={"/" + list.url}
                                    className="mx-0 text-decoration-none "
                                  >
                                    <h3 className="rt-title title">
                                      {list.title}{" "}
                                    </h3>
                                    <p className="rt-subtitle subtitle">
                                      {list.subtitle}
                                    </p>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="justify-content-end d-flex menu-atlas-icon">
                        <Link to="/">
                          <img src="/images/atlas.svg" alt="atlas" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item pt-3 pt-md-2 d-none">
                <Link
                  className="nav-link common-text-inter"
                  aria-current="page"
                  to="/"
                >
                  Clients
                </Link>
              </li>
              <li className="nav-item pt-3 pt-md-2 ">
                <Link
                  className="nav-link common-text-inter"
                  aria-current="page"
                  to="/about"
                  activeClassName="active_Link"
                >
                  About us
                </Link>
              </li>
              <li className="nav-item pt-3 pt-md-2 dropdown li-menu why-atlas-menu">
                <div className="dummy-area">&nbsp;</div>
                <Link
                  className="nav-link common-text-inter"
                  aria-current="page"
                  to="/why-atlas"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  activeClassName="active_Link"
                  // onMouseOver={() => setWhyAtlas(true)}
                  // onMouseLeave={changeState}
                >
                  Why Atlas
                </Link>

                <div className="menu-overlap li-submenu">
                  <div>
                    <div
                      className="dropdown-menu WhyAtlasCard co"
                      id="WhyAtlasCard"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <div className="dropdown_title">
                        <Link
                          to={"/" + whyus.url}
                          className="mx-0 text-decoration-none "
                        >
                          <h3 className="">{whyus.title} </h3>
                        </Link>
                      </div>

                      {whyus.list.map((list) => (
                        <div className="dropdown_data" key={list.id}>
                          <Link
                            to={"/" + list.url}
                            className="mx-0 text-decoration-none "
                          >
                            <h3 className="">{list.title} </h3>
                            <p className="">{list.subtitle}</p>
                          </Link>
                        </div>
                      ))}

                      <div className="justify-content-end d-flex menu-atlas-icon">
                        <Link href="/">
                          <img src="/images/atlas.svg" alt="atlas" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <button
                className="contactbutton d-none d-md-block "
                data-bs-toggle="modal"
                data-bs-target="#getintouchmodal"
                onClick={handleShow}
              >
                Contact
              </button>

              <MobMenu
                onshow={mobShow}
                onMobClose={handleMobClose}
                onMobShow={handleMobShow}
              />

              <ContactUsForm
                show={show}
                onClose={handleClose}
                onShow={handleShow}
              />

              <div className="d-flex pt-2  ms-lg-2  pt-md-0 d-none">
                <img
                  className="img-fluid my-auto"
                  src="/images/uk.svg"
                  alt=""
                />
                <h2 className="language my-auto ms-1 ">EN</h2>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
