import React from "react";

import Modal from "react-bootstrap/Modal";
import data from "../../data/Lang/English/homePage.json";
import { Link } from "gatsby";

const { inventory, inventorytwo, ordermenu, whyus } = data[0].Menu;

export default function MobMenu(props) {
  const { onshow, onMobClose } = props;

  return (
    <>
      <Modal show={onshow} onHide={onMobClose}>
        <div>
          <div className="close-menu" onClick={onMobClose}>
            <img
              src="/images/close-menu-icon.svg"
              alt=""
              id="close-menu-icon"
            />
          </div>
          <div
            className="dropdown-menu solution-card-menu ps-set co"
            id="home-menu-dd-wrapper"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <div className="table-wrapper">
              <div className="lt-container">
                <div className="header lt-header">
                  <Link
                    to={"/" + ordermenu.url}
                    className="text-decoration-none mx-0"
                  >
                    <p>{ordermenu.title}</p>
                  </Link>
                </div>

                {ordermenu.list.map((list) => (
                  <div className="lt-sub-header" key={list.id}>
                    <Link
                      to={"/" + list.url}
                      className="mx-0 text-decoration-none"
                    >
                      <h3 className="lt-title title">{list.title} </h3>
                      <p className="lt-subtitle subtitle">{list.subtitle}</p>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="ct-divider"></div>
              <div className="rt-container">
                <div className="header rt-header">
                  <Link
                    to={"/" + inventory.url}
                    className="mx-0 text-decoration-none"
                  >
                    <p>{inventory.title}</p>
                  </Link>
                </div>

                <div className="sub-wrapper">
                  <div className="sub-one">
                    {inventory.list.map((list) => (
                      <div className="rt-sub-header hm-lg-6" key={list.id}>
                        <Link
                          to={"/" + list.url}
                          className="mx-0 text-decoration-none"
                        >
                          <h3 className="rt-title title">{list.title} </h3>
                          <p className="rt-subtitle subtitle">
                            {list.subtitle}
                          </p>
                        </Link>
                      </div>
                    ))}
                  </div>

                  <div className="sub-two">
                    {inventorytwo.list.map((list) => (
                      <div className="rt-sub-header hm-lg-6" key={list.id}>
                        <Link
                          to={"/" + list.url}
                          className="mx-0 text-decoration-none "
                        >
                          <h3 className="rt-title title">{list.title} </h3>
                          <p className="rt-subtitle subtitle">
                            {list.subtitle}
                          </p>
                        </Link>
                      </div>
                    ))}
                  </div>

                  <div className="header rt-header menu-sm-block">
                    <Link to="#" className="mx-0 text-decoration-none d-none">
                      <p>Clients</p>
                    </Link>
                  </div>

                  <div className="header rt-header menu-sm-block">
                    <Link to="/about" className="mx-0 text-decoration-none">
                      <p>About us</p>
                    </Link>
                  </div>

                  <div className="header rt-header  border-0 menu-sm-block">
                    <Link to="/why-atlas" className="mx-0 text-decoration-none">
                      <p className=" border-0">Why Atlas</p>
                    </Link>
                  </div>

                  <div className="sub-three menu-sm-block">
                    {whyus.list.map((list) => (
                      <div className="rt-sub-header hm-lg-6" key={list.id}>
                        <Link
                          to={"/" + list.url}
                          className="mx-0 text-decoration-none "
                        >
                          <h3 className="rt-title title">{list.title} </h3>
                          <p className="rt-subtitle subtitle">
                            {list.subtitle}
                          </p>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="justify-content-end d-flex menu-atlas-icon">
              <Link to="/">
                <img src="/images/atlas.svg" alt="atlas" />
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
